.forgot-password-container{
  padding-top: calc($spacing * 3);
  
  .horoscope-circle-container{
    margin-bottom: calc($spacing * 1.5);
  }

  .glass-box{
    margin-top: calc($spacing * 5);
  }

  .footer-text{
    font-size: 16px;
    line-height: 20.16px;
    margin-top: 16px;
  }
}