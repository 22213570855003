.home-container{
  padding-top: calc($spacing * 1.5);
  padding-bottom: calc($spacing * 5);

  .top-row{

    display: grid;
    grid-template-columns: 58px 1fr 58px;
    
    .top-row-button{
      background: linear-gradient(280.04deg, rgba($color: $white, $alpha: 0.15) -1.97%, rgba($color: $white, $alpha: 0.05) 81.79%);
      height: 58px;
      backdrop-filter: blur(25px);
      border-radius: $spacing;
      border: 1px solid rgba($color: $white, $alpha: 0.5);
      width: 76px;
      gap: 4px;
      font-size: 14px;
      font-weight: 500;

      &.square{
        width: 58px;
      }
    }

    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
    }
  }

  .signs-row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 12px;
    margin-top: calc($spacing * 1.5);
    
    .signs-container{
      text-decoration: none !important;
      color: $white !important;
    }
    .sign-box{
      // height: 122px;
      aspect-ratio: 1;
      background: linear-gradient(276.2deg, rgba($color: $white, $alpha: 0.1) -1.02%, rgba($color: $white, $alpha: 0.05) 101.25%);
      backdrop-filter: blur(25px);
      border-radius: 30px;
      border: 1px solid rgba($color: $white, $alpha: 0.5);
      // border: 1px solid;
      // border-image-source: linear-gradient(198.83deg, rgba(255, 255, 255, 0.5) 2.45%, rgba(255, 255, 255, 0.2) 25.41%);
    }

    .label{
      font-size: 16px;
      line-height: 19.36px;
      margin-top: 15px;
    }
  }
}