.zodiac-details-container {
  padding-top: calc($spacing * 1.5);
  padding-bottom: calc($spacing * 5);

  .top-row {
    display: grid;
    grid-template-columns: 58px 1fr 58px 76px;
    gap: calc($spacing / 2);

    .top-row-button {
      background: linear-gradient(280.04deg, rgba($color: $white, $alpha: 0.15) -1.97%, rgba($color: $white, $alpha: 0.05) 81.79%);
      height: 58px;
      backdrop-filter: blur(25px);
      border-radius: $spacing;
      border: 1px solid rgba($color: $white, $alpha: 0.5);
      width: 76px;
      gap: 4px;
      font-size: 14px;
      font-weight: 500;

      &.square {
        width: 58px;
      }
    }

    .language-dropdown-container{
      .language-dropdown{
        width: calc($spacing * 5.5);
        height: calc($spacing * 4);
        top: calc($spacing * 2.5);
        right: 0;
        border-radius: 20px;
        border: 1px solid rgba($color: $white, $alpha: 0.5);
        background: linear-gradient(276.2deg, rgba(255, 255, 255, 0.1) -1.02%, rgba(255, 255, 255, 0.05) 101.25%);
        backdrop-filter: blur(25px);
        opacity: 0;
        visibility: hidden;
        z-index: 10;

        &.show{
          opacity: 1;
          visibility: visible;
        }

        .language{
          height: calc($spacing * 2);
          padding: 0 12px;
          font-size: 14px;
          font-weight: 500;

          &.active{
            background-color: $black;
            border-radius: 20px;
          }
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .sign-container {
    height: calc($spacing * 13);
    width: calc($spacing * 13);
    background: linear-gradient(280.04deg, rgba(255, 255, 255, 0.15) -1.97%, rgba(255, 255, 255, 0.05) 81.79%);
    backdrop-filter: blur(25px);
    border-radius: calc($spacing * 4);
    border: 1px solid rgba($color: $white, $alpha: 0.5);
    margin-top: $spacing;
    margin-bottom: 24px;

    .sign {
      width: calc($spacing * 10);
      height: auto;
    }
  }

  .title {
    font-size: 48px;
    font-weight: 600;
    line-height: 58.09px;
    margin-bottom: 2px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-bottom: 36px;
  }

  .description{
    white-space: pre-line;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: rgba($color: $white, $alpha: 0.8);
  }
}