body{
  font-family: "Inter", sans-serif;
  color: $white;
  background-color: #1F1F1F;
}

.site-container{
  width: 100%;
  max-width: 430px;
  padding: 0 $spacing $spacing $spacing;
  min-height: 100dvh;
  background-image: url(../static/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: center;
  background-attachment: fixed;
}

.page-title{
  font-size: 45px;
  line-height: 54.46px;
  font-weight: 600;
}

.page-subtitle{
  font-size: 16px;
  line-height: 19.36px;
  margin-top: calc($spacing / 2);
}

.glass-box{
  color : rgba($color: $black, $alpha: 1.0);
  background: linear-gradient(rgba($color: $black, $alpha: 0), rgba($color: $black, $alpha: 0.3));
  padding: 30px 24px;
  backdrop-filter: blur(7.2px);
  border: 1px solid rgba($color: $borderColor, $alpha: 0.5);
  border-radius: $spacing;

  .input-column{
    gap: $spacing;
    margin-bottom: calc($spacing * 1.5);
    .form-link{
      line-height: 20.16px;
    }

    .input-container{
      width: 100%;
      
      .input{
        height: calc($spacing * 3);
        border-radius: 12px;
        border: 1px solid $white;
        width: 100%;
        background-color: transparent;
        color: $white;
        padding: 0 $spacing;

        &:focus{
          outline: none;
        }
      }

      label{
        color: $white;
        line-height: 19.36px;
        margin-bottom: 14px;
      }
    }
  }
}

.button-filled{
  height: 60px;
  background-color: $white;
  color: $black;
  // box-shadow: 0px 8px 16px 0px rgba($color: $white, $alpha: 0.1);
  border: 1px solid $white;
  border-radius: 12px;
  font-weight: 600;

  &:disabled{
    opacity: 0.5;
  }
}

.button-bordered{
  height: 60px;
  background-color: transparent;
  color: $white;
  // box-shadow: 0px 8px 16px 0px rgba($color: $white, $alpha: 0.1);
  border: 1px solid $white;
  border-radius: 12px;
  font-weight: 600;
}

.bg-stars{
  top: -52px;
  left: 0;
}

.rotate{
  animation: rotate 20s infinite linear;
}

@keyframes rotate {
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}

.password-input-container{
  height: calc($spacing * 3);
  border-radius: 12px;
  border: 1px solid $white;
  width: 100%;
  background-color: transparent;
  color: $white;
  padding-right: $spacing;
  
  input{
    height: 100%;
    width: 100%;
    background-color: transparent;
    color: $white;
    padding: 0 $spacing;
    border: none;

    &:focus{
      outline: none;
    }
  }
}

.form-link{
  color: $white;
  text-decoration: underline;

  &:hover{
    color: $white;
  }
}

.page-loader{
  background-color: #1F1F1F;
  z-index: 2100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;

  &.show{
    opacity: 1;
    visibility: visible;
  }
}