.footer {
  position: fixed;
  height: 80px;
  width: 240px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(217.85deg, rgba(255, 211, 187, 0.3) 5.8%, rgba(64, 64, 64, 0.3) 56.99%);
  backdrop-filter: blur(15px);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  gap: 22px;
  padding-top: 6px;

  .icon-container {
    height: 56px;
    width: 56px;
  }
}

.popup-container {
  background-color: rgba($color: #1F1F1F, $alpha: 0.8);
  padding: $spacing;
  opacity: 0;
  visibility: hidden;

  &.show{
    opacity: 1;
    visibility: visible;
  }

  .popup {
    background: linear-gradient(276.2deg, rgba(255, 255, 255, 0.1) -1.02%, rgba(255, 255, 255, 0.05) 101.25%);
    backdrop-filter: blur(25px);
    padding: $spacing 14px;
    border-radius: 30px;
    border: 1px solid rgba($color: $white, $alpha: 0.5);

    .popup-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: calc($spacing / 2);
    }

    .popup-description {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      white-space: pre-line;
    }

    .popup-close-button{
      width: 58px;
      height: 58px;
      border-radius: 20px;
      border: 1px solid rgba($color: $white, $alpha: 0.5);
      background: linear-gradient(280.04deg, #5F5F5F -1.97%, #494949 81.79%);
      top: -40px;
      right: 10px;
    }
  }
}