.splash-container{
  padding-top: calc($spacing * 2);
  
  .horoscope-circle-container{
    margin-bottom: 20px;
  }
  .glass-box{
    margin-top: 24px;
    gap: calc($spacing / 2);
  }
}